.main-section {
    display: flex;
    height: 44em;
    padding: 0 2em;
    margin-bottom: 4em;

    .left-side {
        width: 25%;
        background-color: rgba(var(--full-color), 0.75);
    }

    .right-side {
        width: 75%;
        position: relative;
        overflow: hidden;
        background-color: rgba(var(--full-color), 0.5);
    }
}

@media only screen and (max-width: 1600px) {
    .main-section {
        flex-direction: column;
        height: max-content;
        width: 100%;
        .right-side,
        .left-side {
            width: 100%;
            margin-bottom: 4em;

        }
    }
}

@media only screen and (max-width: 800px) {
    .main-section {
        padding: 0;
    }
}