// colors.scss

:root {
  --color-0: #75b4df;
  --color-1: #7996AA;
  --color-2: #45647D;
  --color-3: #203348;
  --color-4: #0C1A28;


  --text-color: #F0F0F0;
  --text-color-inverted: #0C1A28;

  --bg-color: #0C1A28;

  --full-color: 10, 10, 20;
  --full-color-inverted: 235, 235, 245;

  --accent-color-1: #AA8D79;
  --accent-color-2: #e6a981;
  --accent-color-3: #e97f38;

  --footer-bg-color: rgba(var(--full-color), 0.75);

  --bg-image: url('../@assets/images/bg-dark.png');
}

.inverted-black-img {
  filter: invert(1);
}

.button {
  background-color: var(--color-2);
  transition: background 0.15s;
  box-shadow: 0 0 0.25em 0.05em var(--color-3);
  font-weight: bold;
  &:hover {
    background-color: var(--color-1);
  }

  &:disabled {
    opacity: 0.25;
    pointer-events: none;
  }
}

a {
  font-style: italic;
  color: var(--color-0);

  &:hover {
      color: var(--color-1);

  }
}
