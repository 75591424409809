.waitlist-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    height: 100%;
    position: relative;

    .waitlist {
        width: 100%;
        height: 50%;
        padding: 6.25em 2em;
        transition: all 0.5s;

        &.animate {
            transform: scale(1.3);

            .title {
                color: var(--accent-color-3);
            }
        }

        .title {
            font-size: 2.25em;
            color: var(--accent-color-2);
            margin-bottom: 0.5em;
        }

        form {
            width: 100%;

            .form-wrapper {
                display: flex;
                flex-wrap: wrap;
                margin-top: 2em;
                width: 100%;

                input {
                    padding: 0 0.5em;
                    flex-grow: 1;
                    background-color: transparent;
                    border-bottom: 2px solid var(--color-2);

                    &:focus {
                        outline: none;
                    }
                }

                button {
                    padding: 0.5em 1.5em;
                }
            }

            .checkbox-wrapper {
                margin-top: 1em;

                label {
                    margin-left: 0.5em;
                    font-size: small;
                }
            }
        }

        .join-message {
            font-size: small;
            margin-top: 0.5em;
            height: 1em;
            color: var(--accent-color-1)
        }
    }

    .line {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        overflow: hidden;
        transform: scale(1);
    }
}

@media only screen and (max-width: 1600px) {
    .waitlist-wrapper {
        text-align: center;

        .sub-title {
            max-width: 50%;
            margin: 0 auto;
        }

        form {
            .form-wrapper {
                flex-direction: column;
                align-items: center;

                input {
                    margin: 2em 0;
                    min-width: 30em;
                }

                button {
                    min-width: 20%;
                }
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .waitlist-wrapper {
        .waitlist {
            padding: 1em;

            .sub-title {
                max-width: unset;
            }

            form {
                .form-wrapper {

                    input,
                    button {
                        min-width: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
}