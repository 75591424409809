.features-slide-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    padding-bottom: 3em;
    .card-wrapper {
        width: 100%;
        transition: transform 0.15s;
        position: relative;
        cursor: crosshair;
        .card {
            box-shadow: 0 0 0.5em 0.01em var(--color-3);
            display: flex;
            flex-direction: column;
            background-color: var(--color-4);
            height: 100%;
            margin: 0 1em;
            &:hover {
                transform: scale(1.1);
            }
            .title {
                text-align: center;
                font-weight: bold;
                padding: 1em 0;
                background-color: var(--accent-color-1);
                color: var(--color-4)
            }
            .text {
                transition: all 1s;
                padding: 0.5em;
                text-align: justify;
                display: flex;
                flex-grow: 1;
            }
        }

        .disabled {
            opacity: 0.15;
        }

        .comming-soon {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(-45deg);
            font-size: 3em;
        }
    }
}

@media only screen and (max-width: 800px) {
    .features-slide-wrapper {
        flex-direction: column;
        .card-wrapper {
            margin: 3em 0;
            .card {
                &:hover {
                    transform: unset;
                }
            }  
        }
    }
}