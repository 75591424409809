.right-side-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    transition: right 0.5s;
}

@media only screen and (max-width: 1280px) {
    .right-side-wrapper {
        flex-direction: column;
        position: unset;
    }
}