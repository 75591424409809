.wellcome-slide-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .wellcome-message {
        height: 50%;
        max-height: 50%;
        .title {
            font-size: 2.5em;
            font-weight: bold;
            text-align: center;
            span {
                color: var(--accent-color-2)
            }
        }
        .sub-wrapper {
            padding: 1em 0;
            text-align: justify;
            display: flex;
            justify-content: space-between;
            .left, .right {
                width: 48%;
            }

            .left {
                text-indent: 2em;
                span.accent-2 {
                    color: var(--accent-color-2);
                }
    
                span.color-0 {
                    color: var(--color-0);
    
                }
            }
            .right {
                display: flex;
                justify-content: center;
                .card-wrapper {
                    position: relative;
                    display: flex;
                    .backdrop {
                        position: absolute;
                        inset: 0;
                        background: linear-gradient(90deg, var(--color-1) 0%, rgba(0,0,0,0) 80%);
                        opacity: 0.7;
                        transform: skewY(6deg) rotate(-10deg);
                    }
                    .card {
                        background-color: var(--color-3);
                        position: relative;
                        padding: 1em 2em;
                        .right-side-title {
                            font-size: 1.5em;
                        }
                        ul {
                            margin-bottom: 1em;
                            list-style-position: inside;
                            li {
                                margin: 0.5em 0;
                                span {
                                    color: var(--accent-color-2)
                                    // font-weight: 600;
                                }

                            }
                        }
                        .more {
                            border-top: 1px solid var(--color-2);
                            width: 80%;
                            a {
                                font-size: 1.25em;
                            }
                        }
                    }
                }
            }

        }

    }
    .alert-wrapper {
        height: 50%;
        text-align: center;
        display: flex;
        align-items: center;
        .alert {
            padding: 1em;
            position: absolute;
            width: 100%;
            left: 0;
            font-size: 1.5em;
            background-color: var(--color-4);
            strong {
                color: var(--color-0)
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .wellcome-slide-wrapper  {
        height: 100%;
        display: block;
        padding: 0 4em;
        padding-bottom: 4em;
        .wellcome-message{
            overflow: hidden;
            height: unset;
            max-height: 100%;
            padding: 2em 0;
            position: relative;

            .sub-wrapper {
                flex-direction: column;
                .left, .right {
                    width: unset;
                    padding: 2em 0;
                }
            }
        }
        .alert-wrapper {
            height: unset;
            padding: 7em 0 3em 0;
        }
    }
}

@media only screen and (max-width: 800px) {
    .wellcome-slide-wrapper  {
        padding: 0 1em;
    }
}