.contact-section-wrapper {
    margin: 4em auto;
    .title {
        font-size: 2em;
        text-align: center;
    }
    form {
        input, textarea {
            width: 100%;
            padding: 0.5em 1em;
            background-color: var(--color-3);
            border: none;
            border-bottom: 2px solid var(--color-2);
            margin: 1em 0;
            &:focus {
                outline: none;
                border-bottom: 2px solid var(--color-1);

            }
        }

        textarea {
            min-height: 10em;
            resize: none;
        }

        button {
            width: 100%;
            padding: 0.5em 1em;
        }

        .message {
            margin-top: 2em;
            text-align: center;
            font-size: small;
            color: var(--accent-color-1);
        }
    }
}