footer {
    width: 100%;
    flex-grow: 1;
    background-color: var(--footer-bg-color);
    z-index: 1;
    .footer-inner-wrapper {
        max-width: 144em;
        height: 100%;;
        margin: 0 auto;
        text-align: center;
        padding: 2em;
        .socials {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.75em;
            a {
                img {
                    // className='h-6 mx-2 cursor-pointer hover:scale-125 transition duration-50'
                    height: 1.5em;
                    margin: 0 0.5em;
                    cursor: pointer;
                    transition: transform 0.1s;
                    &:hover {
                        transform: scale(1.25);
                    }
                }
            }
        }
        .info {
            font-size: small;
        }
    }
}