.terms-page-wrapper {
    margin: 2em auto;
    align-items: center;
    max-width: 40em;

    .terms {
        padding: 2em;
        background-color: var(--color-4);
        box-shadow: 0 0 0.5em 0.1em var(--color-3);

        h2 {
            text-align: center;
            font-size: 2em;
        }

        p {
            font-weight: bold;
            color: var(--accent-color-1);
            margin: 1em 0;
        }

        ul {
            list-style: disc;

            li {
                padding: 0.15em 0.5em;
                ;
                margin: 0.15em 0.5em;
            }
        }
    }
    .go-back {
        text-align: center;
        font-size: 2em;
        margin-top: 4em;
        margin-bottom: 2em;
    }
}