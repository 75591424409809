.error-page-wrapper {
    margin: 2em auto;
    align-items: center;
    max-width: 40em;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    h1 {
        font-size: 2em;
        margin: 0.5em 0;
    }
    p {
        font-size: 1.5em;
    }
    img {
        margin: 2em 0;
        width: 20em;
    }
}