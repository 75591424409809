.slider-wrapper {
    min-width: 100%;
    padding: 2em 4em;
    width: 100%;
}


@media only screen and (max-width: 1280px) {
    .slider-wrapper {
        padding: 2em 0em;
    }
}