@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import './@style/colors.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    transition: all 0.25s;
}

body.light-theme {
    background-color: var(--bg-color);
    color: var(--text-color);
}

html,body, #app {
    transition: none;
    scroll-behavior: smooth;
}