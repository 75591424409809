header {
    width: 100%;
    height: 6em;
    z-index: 2;
    position: fixed;
    top: 0;
    z-index: 1;
    transition: all 0.1s;

    .header-inner-wrapper {
        max-width: 144em;
        height: 100%;
        padding: 0 2em;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            background-color: var(--accent-color-3);
            padding: 1em 2em;
            color: var(--color-3);
            &:hover {
                background-color: var(--accent-color-2);
            }
        }
        .logo {
            max-height: 100%;
            height: 100%;
            padding: 0.5em 0;
            a {
                display: flex;
                height: 100%;
                align-items: center;
                img {
                    height: 100%;
                    transition: all 0.5s;
                    transform: scale(1.3);
                }
                &:hover > img {
                    transform: scale(1.3) rotate(720deg) ;
                }

                .logo-text {
                    font-size: 1.75em;
                    font-weight: bold;
                    color: white;
                }
            }
        }
    }
}

header.scrolled {
    background-color: var(--color-4);
    opacity: 0.975;
    z-index: 2;
    box-shadow: 0 0 0.5em 0.01em rgba(0, 0, 0, 0.5);
}

@media only screen and (max-width: 800px) {
    header {
        position: relative;
        height: unset;
        margin-bottom: 2em;
        .header-inner-wrapper {
            flex-direction: column;
            .logo {
                a {
                    flex-direction: column;

                    img {
                        max-height: 10em;

                    }
                }
            }
        }
    }

    header.scrolled {
        background-color: unset;
        opacity: unset;
        z-index: unset;
        box-shadow: unset;
    }
}