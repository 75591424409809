.layout-wrapper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 6em;
    .bg-image {
        background-image: var(--bg-image);
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        filter: blur(0.1em);
        background-position: center;
    }
    .inner-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 144em;
        width: 100%;
        margin: 0 auto;
        z-index: 1;
        align-items: center;
    }
}

@media only screen and (max-width: 800px) {
    .layout-wrapper {
        padding-top: unset;
    }
}