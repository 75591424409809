.slider-navigation-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    pointer-events: none;

    .buttons-wrapper {
        display: flex;
        width: 100%;
        padding: 0 1em;

        .nav-button {
            border-radius: 100%;
            padding: 0.75em;
            box-shadow: 0 0 0.1em 0.025em var(--text-color);
            transition: transform 0.15s;
            cursor: pointer;
            pointer-events: all;

            &:hover {
                transform: scale(1.15);
                opacity: 1;
            }

            img {
                width: 1em;
            }

            .rotated {
                transform: rotate(180deg);
            }
        }

        .disabled {
            opacity: 0.05;
            pointer-events: none;
        }

        .pulsing {
            animation-name: pulse;
            animation-duration: 1s;
            animation-iteration-count: infinite;
        }
    }

    .bullets {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        justify-content: center;
        margin-bottom: 1em;

        .bullet {
            width: 0.5em;
            height: 0.5em;
            background-color: var(--color-2);
            border-radius: 100%;
            margin: 0 0.5em;
            transition: transform 0.15s;
            pointer-events: all;
            cursor: pointer;
        }

        .selected-bullet {
            transform: scale(1.75);
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.25);
    }

    100% {
        transform: scale(1);
    }
}

@media only screen and (max-width: 1280px) {
    .slider-navigation-wrapper {
        display: none;
    }
}